.logs-viewer {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: grid;
  margin: 10px;
  gap: 10px;
  grid-template:
    "log-header-type  log-header-lines log-header-rate"  max-content
    "log-body         log-body         log-body       "  1fr
    "log-footer       log-footer       log-footer     "  max-content
    /1fr              1fr              1fr;
}

#logs-viewer-header-rate {
  grid-area: log-header-rate;
  font-size: 15px;
}

#logs-viewer-header-lines {
  grid-area: log-header-lines;
  font-size: 15px;
}

#logs-viewer-body {
  grid-area: log-body;
}

#logs-viewer-footer {
  grid-area: log-footer;
  text-align: center;
  font-size: 10px;
}

#logs-viewer-textarea {
  width: 100%;
  height: 100%;
}

