:root {
    --app-bg-color: #CCEECC;
}

.App {
  background-color: var(--app-bg-color);
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: grid;
  gap: 0px;
  grid-template:
    [header-left] "header" max-content  [header-right]
    [body-left]   "body"   1fr          [body-right]
    /               1fr;
}

#header {
  background-color: #55BB55;
  grid-area: header;
  font-size: calc(10px + 2vmin);
  color: white;

  display: grid;
  gap: 10px;
  grid-template:
     "lmargin actions   title   user rmargin"   1fr
    / 10px    1fr       5fr     1fr  10px;
}

#header-actions {
  grid-area: actions;
  font-size: 3vmin;
  width: 100%;
  max-width: 100vw;
}

#header-title {
  grid-area: title;
  text-align: center;
  font-size: 5vmin;
}

#header-user {
  grid-area: user;
  font-size: 3vmin;
  justify-items: end;
  display: grid;
}

#btn_logout {
  width: fit-content;
  height: fit-content;
}

#body {
  position: relative;
  grid-area: body;
  display: flex;
  flex-direction: column;
}

#App-loading {
  height: 100vh;
  text-align: center;
  justify-content: center;
  align-content: center;
  display: grid;
  font-size: calc(40px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
