.vpnstats {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: grid;
  margin: 10px;
  gap: 10px;
  grid-template:
    "ns-header       ns-header       ns-header     "  max-content
    "ns-body-mar-l   ns-body         ns-body-mar-r "  1fr
    "ns-footer       ns-footer       ns-footer     "  max-content
    /1fr             8fr             1fr;
}

#vpnstats-header {
  grid-area: ns-header;
  text-align: center;
  font-size: 15px;
}

#vpnstats-body {
  grid-area: ns-body;
}

#vpnstats-footer {
  grid-area: ns-footer;
  text-align: center;
  font-size: 10px;
}

#vpnstats-textarea {
  width: 100%;
  height: 100%;
}

th, td {
  padding-left: 10px;
  padding-bottom: 5px;
}
