:root {
    --title-height: 30px;
    --box-color: #00FFCC;
}

.outter_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-draggable {
    width: fit-content;
}

.inner_box {
  border: 4px double black;
  width: 100%;
  height: calc(100% - var(--title-height) / 2 - 4px);
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--box-color);
}

.content_box {
  width: 100%;
  height: calc(100% - var(--title-height) / 2 - 2px);
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-style {
  border: 2px solid black;
  min-width: 50%;
  height: var(--title-height);
  text-align: center;
  vertical-align: middle;
  background-color: var(--box-color);
  position: absolute;
  top: 0;
  cursor: move;
  z-index: 1;
}

.close-button {
  width: 20px;
  height: 20px;
  text-align: center;
  background-color: var(--box-color);
  position: absolute;
  top: -4px;
  right: -4px;
  cursor: pointer;
  border: 2px solid black;
}
