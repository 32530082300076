.login-form {
  width: 100%;
  flex-grow: 1;
  display: flex;
  margin: 10px;
  gap: 5px;
  grid-template:
    "login-form-user        log-header-rate"  max-content
    "login-form-password    log-body       "  max-content
    "login-form-button      log-footer     "  max-content
    "login-form-status      log-footer     "  max-content
    / 1fr                   1fr;
}

#lbl_login_status {
  font-size: 20px;
}
